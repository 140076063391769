import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { PROJECT_LINK, VID } from "../project-config";
import { I18nContext } from "../i18n/index";
// import ExternalContentFromCMS from "../components/AC-ExternalContentFromCMS/ExternalContentFromCMS";
// import sdfsdf from "../header/SearchHelper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      img2: file(relativePath: { eq: "slider/slider5_en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { langCode } = React.useContext(I18nContext);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  let imgName = `mobileslider$NUM_${langCode}.png`;
  const images = require.context("../assets/img/mobileslider/", true);

  let img1 = images("./" + imgName.replace("$NUM", "1"));
  let img2 = images("./" + imgName.replace("$NUM", "2"));
  let img3 = images("./" + imgName.replace("$NUM", "3"));
  let img4 = images("./" + imgName.replace("$NUM", "4"));

  return (
    <>
    
      <div id="homeBanner">
        
        <Img
          className="mobile-home-banner-image"
          sizes={{ ...data.img2.childImageSharp.fluid, aspectRatio: 360 / 406 }}
        />

        <div className="mobileSliderBtns">
          <p>Rotary Club of Barrie Shop</p>

          {/* {navCatsState &&
            navCatsState.childs.map(child => {
              const { URL, description, name } = child;
              if (name !== "Sellers")
                return (
                  <Link key={name} to={URL.replace("shop", "")}>
                    {description.replace("&#39;", "'")}
                  </Link>
                );
            })}
          <div className="sliderSellerBtn">
            {navCatsState &&
              navCatsState.childs.map(child => {
                const { URL, description, name } = child;
                if (name === "Sellers")
                  return (
                    <Link key={name} to={URL.replace("shop", "")}>
                      {description.replace("&#39;", "'")}
                    </Link>
                  );
              })}
          </div> */}
        </div>
      </div>
      {/*<ExternalContentFromCMS*/}
      {/*  key="Slider"*/}
      {/*  place="home"*/}
      {/*  position="Middle"*/}
      {/*  renderedBy="Slider"*/}
      {/*/>*/}
    </>
  );
};

export default Slider;
