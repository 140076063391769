import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "./FeaturedPromotions.css";

import Image1 from "../assets/img/slider/1.jpg";
import Image2 from "../assets/img/slider/2.jpg";
import Image3 from "../assets/img/slider/3.jpg";
import Image4 from "../assets/img/slider/4.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const FeaturedAbout = () => {
  const data = useStaticQuery(graphql`
    query {
      Image1: file(relativePath: { eq: "auction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image2: file(relativePath: { eq: "imagin.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image3: file(relativePath: { eq: "slider/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image4: file(relativePath: { eq: "slider/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <div
      style={{
        marginTop: "0%"
      }}
    >
      <div className="newsletter-wrapper">

        <div className="mailchimp-wrapper">
          {/*<Carousel
            additionalTransfrom={0}
            arrows={false}
            // autoPlay
            autoPlaySpeed={4000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 1
              }
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <Img fluid={data.Image1.childImageSharp.fluid} />
            <Img fluid={data.Image2.childImageSharp.fluid} />
            <Img fluid={data.Image3.childImageSharp.fluid} />
            <Img fluid={data.Image4.childImageSharp.fluid} />
          </Carousel>*/}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/v0Pq2O5If5E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

        <div className="newsletter-content newsletter-content1">
          <h3>
            What's our club about?
          </h3>
          <p className="auction-content">
            Rotary International is 1.2 million neighbours, friends, and community leaders who come together to create positive, lasting change in our communities and around the world.
          </p>
          <p className="auction-content">Rotary is where neighbors, friends, and problem-solvers share ideas, join leaders, and take action to create lasting change.</p>
          <p className="auction-content">Our differing occupations, cultures, and countries give us a unique perspective. Our shared passion for service helps us accomplish the remarkable.</p>
          {/* <span className="cafe-button">
            <Link to={"/seller-registration"} onClick={e => e.preventDefault()}>Learn More</Link>
          </span> */}
        </div>

      </div>

      {/* <Link
            to={
              lang !== "en"
                ? PREVIEW + `/${lang}` + "/shop/laptops"
                : PREVIEW + "/shop/laptops"
            }
            onClick={() =>
              handleCategoryChange(
                "432307",
                "Laptops",
                ["Laptops", "432307"],
                "Laptops"
              )
            }
          ></Link>
            
            
              <h3 className="cafe-banner-title">
              >
              
                
                
              
          </Link> */}
    </div>
  );
};

export default FeaturedAbout;
