import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
// import ExternalContentFromCMS from "../components/AC-ExternalContentFromCMS/ExternalContentFromCMS";

import "./FeaturedPromotions.css";

import Image1 from "../assets/img/slider/1.jpg";
import Image2 from "../assets/img/slider/2.jpg";
import Image3 from "../assets/img/slider/3.jpg";
import Image4 from "../assets/img/slider/4.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const FeaturedPromotions = () => {
  const data = useStaticQuery(graphql`
    query {
      Image1: file(relativePath: { eq: "domicians.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image2: file(relativePath: { eq: "slider/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image3: file(relativePath: { eq: "slider/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Image4: file(relativePath: { eq: "slider/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  return (
    <div
      style={{
        marginTop: "4%"
      }}
    >
      <div className="newsletter-wrapper">
        <div className="newsletter-content">
          <h3>
            Enriching Dominican Lives <br/> with Music
          </h3>
          <br/>
          <p className="auction-content">One of the projects our Rotary Club's International Committee was involved with earlier this year, was anOutdoor Musical Instrument Installation Project in the Dominican Republic.</p>
          <p className="auction-content">The purpose of this project was to improve the lives of poor and disadvantaged people living in the Puerto Plata region through the installation of an outdoor xylophone.</p>
          <p className="auction-content">The xylophone was purchased in Canada, then shipped to the Dominican Republic and installed in the village of Pancho Mateo by Rotarians during an international service mission that took place between January 15 and February 5, 2020.</p>
          <span className="cafe-button">
            <Link to="https://www.barrierotary.com/single-post/enriching-dominican-lives-with-music"
                  target="blank" onClick={e => e.preventDefault()}>Learn More</Link>
          </span>
        </div>

        <div className="mailchimp-wrapper">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            // autoPlay
            autoPlaySpeed={4000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 1
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 1
              }
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <Img fluid={data.Image1.childImageSharp.fluid} />
            {/* <Img fluid={data.Image2.childImageSharp.fluid} />
            <Img fluid={data.Image3.childImageSharp.fluid} />
            <Img fluid={data.Image4.childImageSharp.fluid} /> */}
          </Carousel>
        </div>
      </div>

      {/*<ExternalContentFromCMS*/}
      {/*  key="Slider"*/}
      {/*  place="home"*/}
      {/*  position="Bottom"*/}
      {/*  renderedBy="Slider"*/}
      {/*/>*/}

      {/*<ExternalContentFromCMS*/}
      {/*  key="Slider"*/}
      {/*  place="banners"*/}
      {/*  position="Bottom"*/}
      {/*  renderedBy="Slider"*/}
      {/*/>*/}

      {/* <Link
            to={
              lang !== "en"
                ? PREVIEW + `/${lang}` + "/shop/laptops"
                : PREVIEW + "/shop/laptops"
            }
            onClick={() =>
              handleCategoryChange(
                "432307",
                "Laptops",
                ["Laptops", "432307"],
                "Laptops"
              )
            }
          ></Link>
            
            
              <h3 className="cafe-banner-title">
              >
              
                
                
              
          </Link> */}
    </div>
  );
};

export default FeaturedPromotions;
