import React, { useEffect } from "react";
import Main from "../content/Main";
import Layout from "../other/layout";
import SEO from "../other/seo";
import Product from "../components/ACG-ProductPage/OFBProductPage";

// import { staticContents } from "../components/AC-StaticPages/StaticRoutes.js";

import { navigate, Router, useLocation } from "@reach/router";
import StaticContent from "../components/AC-StaticPages/StaticContent";
import { staticRoutes } from "../../staticRoutes";
//9

const IndexPage = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("location222", location.pathname);
    if (location.pathname.match(/\/{2,}/g)) {
      const newPath = location.pathname.replace(/\/{2,}/g, "/");
      navigate(newPath);
    }
  }, [location.pathname]);

  return (
    <Layout>
      <SEO title="Home" />
      <Router>
        <Main path={"/"} />
        {staticRoutes.map(sta => {
          return <StaticContent path={`/${sta[1]}`} />;
        })}
        <Product dynamic={true} default />
      </Router>
    </Layout>
  );
};

export default IndexPage;
